.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
}

#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.row-app {
    background-color: #ffffff;
}

.header-logo {
    height: 45px;
    /*margin-right: 20px;*/
}

.desktop-mobile {
    height: 40px;
    padding: 0 20px 60px;
    line-height: 55px;
    background-color: #ffffff;
    color: #64b48f;
}

.desktop-header {
    height: 40px;
    margin-top: 5px;
    padding: 0 20px 60px;
    line-height: 55px;
    background-color: #ffffff;
    color: #64b48f;
}

.shadow-on-bottom {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    margin-bottom: 10px;
}

.desktop-header-search {
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    background-color: #f0f2f5;
    color: #64b48f;
}

.header-title {
    font-weight: bold;
    font-size: 120%;
}

.header-seacrh {
    color: #8e8e8e;
    font-size: x-large;
    float: right;
    margin-top: 15px;
}

.header-home-top {
    color: #4b4b4b;
    font-size: x-large;
    margin-top: 18px;
    margin-left: -3px;
}

.span-location-logo {
    color: #64b48f;
    font-size: x-large;
    margin-top: 15px;
}

.location-label {
    color: #b7b7b7;
    font-size: xx-small;
    margin-left: 10px;
}

.header-select {
    width: 100%;
    color: #64b48f;
    font-weight: 600;
    margin-top: -15px;
}

