.card-testimony{
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 3px;
    min-height: 100px;
}

.testi-img{
    margin-left: 10px;
    margin-top: 10px;
}

.testi-name{
    padding-top: 10px;
    font-size: 110%;
    font-weight: 600;
}

.testi-info{
    color: #8e8e8e;
}

.testi-p{
    text-align: justify;
    padding-left: 15px;
    padding-right: 15px;
}
