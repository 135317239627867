.card-simple-simulation{
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 30px;
    margin-left: 5%;
    margin-right: 5%;
    box-shadow: 0px 2px 7px 2px rgb(208 208 208);
    text-align: left;
    padding: 20px;
    min-height: 100px;
}

.icon-simple-simulation{
    color: #64b48f;
    font-size: x-large;
}

.title-simple-simulation{
    color: #64b48f;
    font-size: large;
    padding-left: 7px;
    font-weight: 600;
}

.righticon-simple-simulation{
    color: #64b48f;
    font-size: medium;
    padding-right: 7px;
    font-weight: 600;
}

.mt-12{
    margin-top: 12px;
}

.a-text-simple-simulation{
    color: #8e8e8e;
    padding-left: 5px;
    font-size: medium;
}

.b-text-simple-simulation{
    color: #000;
    font-size: medium;
    text-align: right;
    padding-right: 7px;
    font-weight: 500;
}

.c-text-simple-simulation{
    color: #8e8e8e;
    font-size: x-small;
    padding-left: 7px;
    padding-top: 10px;
    font-weight: 500;
}

.btn-chose-tenor{
    border-radius: 7px;
    padding-top: 3px;
    font-weight: bolder;
    color: #8e8e8e;
    border: solid #8e8e8e 1px;
    background-color: #ffffff;
    text-align: center;
    margin: 5px;
    margin-right: 7px;
    min-width: 40px;
    min-height: 30px;
    cursor: pointer;
}

.btn-chose-tenor-active{
    border-radius: 7px;
    padding-top: 3px;
    font-weight: bolder;
    color: #ffffff;
    background-color: #64b48f;
    text-align: center;
    margin: 5px;
    margin-right: 7px;
    min-width: 40px;
    min-height: 30px;
    cursor: pointer;
}

.row-btn-simple{
    margin-top: 5px;
    margin-bottom: 15px;
}
