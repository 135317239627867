.layout-new-vehicle-variant {
    min-height: 100vh;
}

.variant-header-new-vehicle-variant {
    height: 60px;
    padding: 0 20px;
    line-height: 60px;
    background-color: #fff;
}

.variant-header-new-vehicle-variant-border-bottom {
    height: 65px;
    padding-left: 10px;
    padding-top: -20px;
    margin-top: -20px;
    line-height: 50px;
    background-color: #fff;
    border-bottom: #f0f2f5 3px solid;
}

.hedaer2-back-new-vehicle-variant{
    color: #2a2a2a;
    font-size: 150%;
    margin-top: 20px;
    margin-right: 15px;
    cursor: pointer;
}

.header2-control-new-vehicle-variant{
    color: #2a2a2a;
    font-size: xx-large;
    float: right;
    margin-top: 15px;
    cursor: pointer;
}
