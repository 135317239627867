/* Container holding the image and the text */
.acontainer {
    position: relative;
    text-align: center;
    margin-bottom: 15px;
}

/* Bottom left text */
.bottom-left {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 5px;
    width: 100%;
    text-align: left;
    color: white;
    background-color: rgba(0, 0, 0, 0.55);
}

/* Centered text */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #64b48f;
}

.ant-tabs-ink-bar {
    background: #64b48f;
}
