@import '~antd/dist/antd.css';

.pt-5 {
    padding-top: 20px;
}

.ant-spin {
    color: #64b48f;
}

.ant-spin-dot-item {
    background-color: #64b48f;
}

.header-right {
    float: right;
}

.layout {
    min-height: 100vh;
    background-color: #ffffff !important;
}

.content-main-form {
    background: #fff;
}

.content-main-dashboard {
}

.content-top-padding-20 {
    padding-top: 20px;
    padding-left: 3%;
    padding-right: 3%;
}

.content-top-padding-20-blue {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: medium;
    color: #4db4df;
}

.content-top-padding-20-green {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: medium;
    color: #64b48f;
}

.content-pageheader {
    margin-bottom: 2em;
    background: #fff;
}


.avatar-uploader > .ant-upload {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;
}

.avatar-uploader > .ant-upload img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .content-main-form {
        margin: 6px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .content-main-form {
        max-width: 600px;
        margin: 0px auto;
    }
}

.custom-footer {
    position: absolute;
    bottom: 10px;
    width: 90%;
    height: 2.5rem; /* Footer height */
}

.custom-footer2 {
    position: absolute;
    bottom: 10px;
    width: 94%;
    height: 2.5rem; /* Footer height */
}

.height-100 {
    min-height: 90vh;
}

.height-90 {
    min-height: 85vh;
}

.footer-background {
    background-color: #1d1d1b;
    padding: 30px
}

.footer-div-icon {
    text-align: center;
}

.footer-icon {
    background-color: #fff;
    margin: 12px;
    cursor: pointer;
}

.spin-loading {
    padding-top: 150px;
    padding-bottom: 150px;
    text-align: center;
}

.text-small-grey {
    font-size: 90%;
    color: #8e8e8e;
    padding-bottom: 7px;
}

.text-small-green-nopadding {
    font-size: 90%;
    color: #64b48f;
}

.text-small-black-nopadding {
    font-size: 90%;
    color: #282828;
}

.text-small-grey-nopadding {
    font-size: 90%;
    color: #8e8e8e;
}

.text-extra-small-grey-nopadding {
    font-size: 80%;
    color: #8e8e8e;
}

.text-extra-small-grey-nopadding {
    font-size: 80%;
    color: #8e8e8e;
}

.text-small-black-nopadding {
    font-size: 85%;
    color: #000;
    text-transform: capitalize;
}

.header-icon-right {
    color: #2a2a2a;
    font-size: large;
    float: right;
    margin-top: 15px;
    cursor: pointer;
}

.header2-seacrh {
    color: #2a2a2a;
    font-size: xx-large;
    float: right;
    margin-top: 15px;
    cursor: pointer;
}

.type-variant-product {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5%;
    font-weight: 500;
    font-size: medium;
    color: #4db4df;
}

.name-variant-product {
    margin-top: 10px;
    margin-left: 5%;
    font-weight: 500;
    font-size: large;
    color: #000;
}

.price-variant-product {
    margin-top: 5px;
    margin-left: 5%;
    font-weight: 700;
    font-size: large;
    color: #000;
}

.strikeprice-variant-product {
    margin-top: 5px;
    margin-left: 5%;
    font-weight: 500;
    font-size: small;
    color: #8e8e8e;
    text-decoration: line-through;
    padding-bottom: 20px;
}

.loading {
    font-size: xxx-large;
    margin: 300px 0;
    margin-bottom: 520px;
    padding: 30px 50px;
    text-align: center;
    background: #ffffff;
    border-radius: 4px;
}

.content-top-padding-20 .ant-select-arrow {
    margin-top: -5px !important;
    color: #8e8e8e;
}

.card-box-shadow {
    border-radius: 5px;
    margin-top: 20px;
    box-shadow: 0px 1px 5px 1px rgb(225, 225, 225);
    text-align: left;
    padding: 20px;
    min-height: 100px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px !important;
    font-size: 85%;
}

.ant-collapse-content-box {
    padding: 5px !important;
}

.icon-title-info {
    color: #64b48f;
    font-size: large;
}

.text-title-info {
    color: #727272;
    padding-left: 7px;
    font-size: medium;
    font-weight: 600;
}

.search-box-home {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
}

.search-result {
    padding: 10px;
    cursor: pointer;
    color: #64b48f;
}

.ant-input-search-button {
    border-left-color: #ffffff;
}


/*HORIZONTAL-SCROOL*/
.outer-wrapper {
    max-width: 100vw;
    overflow-x: scroll;
    position: relative;
    scrollbar-color: #C0C0C0FF #c7c7c6;
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

.pseduo-track {
    background-color: #c7c7c6;
    height: 2px;
    width: 100%;
    position: relative;
    top: -3px;
    z-index: -10;
}

@media (any-hover: none) {
    .pseduo-track {
        display: none;
    }
}

.outer-wrapper::-webkit-scrollbar {
    height: 5px;
}

.outer-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.outer-wrapper::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #C0C0C0FF;
}

.outer-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #c7c7c6;
}

.outer-wrapper::-webkit-scrollbar:vertical {
    display: none;
}

.inner-wrapper {
    display: flex;
    padding-bottom: 10px;
}

.pseudo-item {
    height: 20px;
    margin-right: 59px;
    flex-shrink: 0;
    background-color: #C0C0C0FF;
}

.pseudo-item:nth-of-type(2n) {
    background-color: lightgray;
}

/*HORIZONTAL-SCROOL*/

.pt-7 {
    padding-top: 7px;
}

.pt-3 {
    padding-top: 3px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-100 {
    padding-top: 100px;
}

.mt-20 {
    margin-top: 20px;
}

.p-20 {
    padding: 20px;
}

.p-10 {
    padding: 10px;
}

.pl-20 {
    padding-left: 20px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-20 {
    padding-bottom: 20px;
}

.card-product-title {
    font-weight: 500;
    font-size: 110%;
    padding-right: 5px;
}

.card-product-price {
    color: #8f8f8f;
}

.card-promo-caption {
    color: #8f8f8f;
    font-size: 90%;
}

.card-promo-dealer {
    color: #8f8f8f;
    font-size: 95%;
}

.card-promo-caption-100 {
    color: #8f8f8f;
    font-size: 100%;
}

.pt-10 {
    padding-top: 10px;
}

.detail-product-title {
    font-weight: 500;
    font-size: 140%;
}

.fixed-top-button-scroll {
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    position: fixed;
    top: 90px;
    z-index: 200;
    right: 30px;
    font-size: 80%;
    width: 160px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.fixed-bottom-button {
    background-color: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 200;
    padding: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.detail-product-price {
    font-weight: 500;
    font-size: 131%;
}

.detail-product-price-2 {
    font-weight: 500;
    font-size: 140%;
}

.detail-product-caption {
    color: #8f8f8f;
}

.spk-header {
    padding: 0 10px;
    background-color: #fff;
    border-bottom: #f0f2f5 3px solid;
}

.hedaer2-back{
    color: #2a2a2a;
    font-size: 150%;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.header2-title{
    color: #2a2a2a;
    font-size: 125%;
    margin-top: 15px;
    margin-right: 15px;
}

.small-text-grey {
  font-size: 80%;
  color: #7d7d7d;
}

.mt-5{
    margin-top: 5px;
}

.card-preview-thumb-product {
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    cursor: pointer;
}

.card-preview-thumb-product-active {
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0px 2px 7px 2px rgb(49, 154, 73);
    text-align: left;
    cursor: pointer;
}

.color-preview-thumb-product {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;
    border: solid 1px #e3e3e3;
    text-align: left;
    cursor: pointer;
    padding: 5px;
}

.color-preview-thumb-product-active {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;
    border: solid 1px #64b48f;
    color: #528d73;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    padding: 5px;
    background-color: #ebf1ee;
}