.boxHover:hover {
  box-shadow: 0 0 10px 5px rgba(213, 208, 208, 0.5);
}

.card-product {
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 3%;
    margin-right: 3%;
    box-shadow: 0px 2px 7px 2px rgb(236 236 236);
    text-align: left;
    padding: 0px;
    padding-bottom: 15px;
    min-height: 190px;
    cursor: pointer;
}

.p-chosen-name {
    font-size: 90%;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    min-height: 25px;
}

.p-chosen-name-used {
    font-size: 90%;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    margin-left: 10px;
    min-height: 25px;
}

.p-chosen-price {
    color: #64b48f;
    margin-left: 10px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 110%;
}

.p-chosen-price-used {
    color: #64b48f;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 120%;
}

.p-chosen-strikethroughprice {
    float: right;
    font-size: 80%;
    padding-top: 5px;
    margin-right: 10px;
    font-weight: 500;
    color: #8e8e8e;
    min-height: 40px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-decoration: line-through;
}

.product-img-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.img-product-card {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.image-container {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.gallery-mokas {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: 10px;
  justify-items: center;
}

.thumbnail-mokas {
  width: 100%;
  height: 140px;
  object-fit: cover;
  max-height: 140px;
}

.thumbnail-mokas.vertical {
  width: auto;
  height: 140px;
}