table {
    border-collapse: collapse;
    width: 100%;
    margin: 20px auto;
    font-size: 85%;
}

/* Style untuk header tabel */
th {
    background-color: #64b48f;
    color: white;
    padding: 10px;
    text-align: center;
}

/* Style untuk sel tabel */
td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
}

/* Style untuk sel tabel yang berada di baris ganjil */
tr:nth-child(odd) {
    background-color: #f2f2f2;
}