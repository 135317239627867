.variant-header {
    height: 65px;
    padding: 0 20px;
    line-height: 65px;
    background-color: #fff;
    border-bottom: #f0f2f5 3px solid;
}

.hedaer2-back{
    color: #2a2a2a;
    font-size: 150%;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
}

.header2-title{
    color: #2a2a2a;
    font-size: 125%;
    margin-top: 15px;
    margin-right: 15px;
}

