.card-budget{
    /*border-radius: 10px;*/
    margin-top: 20px;
    /*margin-left: 3%;*/
    /*margin-right: 3%;*/
    /*box-shadow: 0px 2px 7px 2px rgb(236 236 236);*/
    text-align: left;
    padding: 3px;
    cursor: pointer;
}

.card-budget-caption{
    margin-top: -30px;
    margin-left: 15px;
    color: white;
}

.img-budget-center{
    display: flex;
    justify-content: center;
    align-items: center
}
