.track-header {
    font-size: 140%;
    color: #000;
}

.express-title{
    font-weight: bold;
    text-align: center;
    font-size: 120%;
    color: #292929;
    padding-top: 7px;
}

.express-description{
    text-align: center;
    font-size: 90%;
    color: #858585;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 8%;
    padding-right: 8%;
}

.track-form{
    padding: 20px;
}


.ant-timeline.ant-timeline-label .ant-timeline-item-label {
    font-size: 95%;
    color: #858585;
}

.timeline-desc{
    font-size: 90%;
    color: #000;
    font-weight: 500;
}
