.d-class-prod{
    width: 94%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 3%;
}

.t-class-prod{
    font-weight: 500;
    color:#8e8e8e;
    margin-right: 30px;
    cursor: pointer;
}

.t-class-prod-active{
    font-weight: 700;
    text-decoration-line: underline;
   text-underline-offset: 7px;
    color:#64b48f;
    margin-right: 30px;
    cursor: pointer;
}
