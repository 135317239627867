.ant-select-arrow{
    /*margin-top: -12px;*/
    color: #8e8e8e;
}

.card-home-display {
    width: 94%;
    margin-top: 20px;
    margin-left: 3%;
    box-shadow: 0px 2px 7px 2px rgb(194 228 205);
    text-align: left;
    border-top: #64b48f solid 5px ;
    margin-bottom: 30px;
}

.pad-five{
    padding-top: 15px;
}

.pad-description{
    color: #313131;
    font-size: 110%;
    font-weight: 700;
    padding-right: 10px;
}

.pad-quiz{
    color: #64b48f;
    font-size: small;
    font-weight: 500;
}

.p-home-menu{
    color: #8e8e8e;
    font-size: small;
    font-weight: 500;
    text-align: center;
}

.chosen-product{
    width: 94%;
    margin-top: 20px;
    margin-left: 3%;
}

.chosen-product-text{
    font-weight: 700;
    font-size: 110%;
    text-transform: capitalize;
}

.chosen-product-other{
    color: #24AE71;
    font-weight: 500;
    font-size: 110%;
    cursor: pointer;
    float: right;
}

.font-140{
    font-size: 140%;
}

.home-row-chosen-product{
    width: 94%;
    margin-left: 3%;
    padding-bottom: 30px;
}

.home-row-chosen-budget{
    background-color: #f0f2f5;
    width: 94%;
    margin-left: 3%;
    padding-bottom: 30px;
}

.chosen-budget{
    width: 94%;
    margin-top: 10px;
    margin-left: 3%;
}

.card-home-find-motor {
    width: 94%;
    margin-top: 20px;
    margin-left: 3%;
    text-align: left;
    margin-bottom: 30px;
    background-color: #E3F9EF;
    color: #24AE71;
    border-radius: 10px;
}

.pad-find-motor{
    color: #24AE71;
    font-size: 110%;
    font-weight: 600;
    padding-right: 10px;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 1px 7px;
    padding-top: 0px;
    right: -20px;
    top: -20px;
    font-size: 17px;
    border-radius: 25px;
    color: white;
    border: 1px solid #478a6b;
    background-color: #478a6b;
}

.popup-content {
    border-radius: 10px;
    padding: 0px !important;
    width: 20% !important;
    min-width: 280px !important;
}


@media (min-width:1367px){
    .popup-content {
        margin-left: 42% !important;
    }
}

@media (min-width:1300px) and (max-width:1366px) {
    .popup-content {
        margin-left: 42% !important;
    }
}

@media (min-width:1200px) and (max-width:1299px) {
    .popup-content {
        margin-left: 41% !important;
    }
}

@media (min-width:1000px) and (max-width:1199px) {
    .popup-content {
        margin-left: 45% !important;
    }
}

/*@media (min-width:400px) and (max-width:900px) {*/
/*    .foo {*/
/*        display:none;*/
/*    }*/
/*}*/
