.card-shadow{
    border-radius: 7px;
    margin-top: 25px;
    margin-bottom: 10px;
    margin-left: 1%;
    margin-right: 1%;
    box-shadow: 0px 1px 3px 1px rgb(208 208 208);
    text-align: left;
    padding: 2px;
    min-height: 100px;
}

.card-shadow-promo{
    border-radius: 7px;
    margin-bottom: 15px;
    box-shadow: 0px 1px 3px 1px rgb(208 208 208);
    min-height: 100px;
}

.card-col{
    min-height: 100px;
}

.ant-card-head-title{
    color: #64b48f;
}

.ant-input-lg{
    cursor: pointer;
}

.grey-color{
    color: #bfbfcd;
}
